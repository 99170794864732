<template>
  <section id="banner" class="align-middle">
    <banner-image-overlay :fullscreen="true"></banner-image-overlay>
    <div class="main-container">
      <h2 class="title">Deseando de conocerte</h2>
      <p>Looking forward to meeting you</p>
      <div class="d-flex justify-content-center">
        <article>
        <form v-on:submit.prevent="onSubmit">
            <div class="form-group">
              <input name="subject" v-model="subject"
                    class="form-control" placeholder="Subject"
                    type="text" required maxlength="128">
            </div>
            <div class="form-group">
              <input name="email" v-model="email"
                    class="form-control" placeholder="My Email"
                    type="email" required maxlength="64">
            </div>
            <div class="form-group">
              <textarea name="message" v-model="message"
                class="form-control" placeholder="Message"
                rows="10" required/>
            </div>
            <div class="alert alert-danger align-left" v-show="error">{{ error }}</div>
            <div class="alert alert-success align-left"
              v-show="successMessage">{{ successMessage }}</div>
            <div class="row">
              <div class="col">
                <div class="form-group align-left">
                  <button type="submit" class="button special">Send</button>
                </div>
              </div>
            </div>
          </form>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import BannerImageOverlay from '../../components/common/utils/BannerImageOverlay.vue';
import types from '../../store/types';
import MiscApi from '../../api/Misc';

export default {
  name: 'Contact',
  components: {
    BannerImageOverlay,
  },
  data() {
    return {
      subject: null,
      message: null,
      email: null,
      error: '',
      successMessage: '',
      user: {},
    };
  },
  computed: {
    ...mapGetters({
      currentUser: types.getters.user.CURRENT_USER,
    }),
  },
  methods: {
    onSubmit() {
      this.error = '';
      MiscApi
        .contact(this.email, this.subject, this.message)
        .then((response) => {
          this.successMessage = response.data.message;
          this.subject = null;
          this.message = null;
          this.email = this.user ? this.user.email : null;
        })
        .catch((error) => {
          this.error = this.parseError(error, 'Unable to send message, please try again');
        });
    },
  },
  created() {
    this.user = this.currentUser;
    if (this.user) {
      this.email = this.user.email;
    }

    if (this.$route.query) {
      if (this.$route.query.subject) {
        this.subject = this.$route.query.subject;
      }
      if (this.$route.query.message) {
        this.message = this.$route.query.message;
      }
    }
  },
};
</script>

<style scoped>
  article {
    width: 500px;
  }

  @media screen and (max-width: 736px) {
    #banner {
      padding-left: 0;
      padding-right: 0;
    }

    .main-container {
      padding-left: 25px;
      padding-right: 25px;
    }
  }
</style>
